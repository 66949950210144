import { render, staticRenderFns } from "./SATType.vue?vue&type=template&id=345ad297&scoped=true"
import script from "./SATType.vue?vue&type=script&lang=js"
export * from "./SATType.vue?vue&type=script&lang=js"
import style0 from "./SATType.vue?vue&type=style&index=0&id=345ad297&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "345ad297",
  null
  
)

export default component.exports